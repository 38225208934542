.FAQ_main{
    padding: 250px 130px;
    padding-bottom: 120px;
    background-color: var(--globalcolor3);
    margin-top: -120px;
    background-image: url('https://whatsapp.hitoritech.com/assets/frontend/img/faq/faq-bg-shape.png');
    background-repeat: no-repeat;
    background-position: bottom;
   
}
.FAQ_text{
    font-size: 16px;
    line-height: 1.8;
}

.FAQ_main>h2{
    font-size: 50px;
    color: var(--globalcolor1);
    margin-bottom: 100px;
    text-align: center;
}
.FAQ_container{
 display: flex;
 flex-direction: column;
 gap: 20px;
}
.FAQ_box{
    width: 100%;
    padding: 35px 30px;
    background-color: var(--globalcolor4);
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    cursor: pointer;
    gap: 20px;
}
.FAQ_activetext{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
@media (max-width:1100px){
    .FAQ_main{
        padding: 730px 80px;
        padding-bottom: 120px;
        background-color: var(--globalcolor3);
        margin-top: -650px;
        background-image: url('https://whatsapp.hitoritech.com/assets/frontend/img/faq/faq-bg-shape.png');
        background-repeat: no-repeat;
        background-position: bottom;
       
    }
    .FAQ_main>h2{
        font-size: 42px;
        color: var(--globalcolor1);
        margin-bottom: 60px;
        text-align: center;
    }
}
@media (max-width:650px){
    .FAQ_main{
        padding: 350px 20px;
        padding-bottom: 120px;
        background-color: var(--globalcolor3);
        margin-top: -300px;
        background-image: url('https://whatsapp.hitoritech.com/assets/frontend/img/faq/faq-bg-shape.png');
        background-repeat: no-repeat;
        background-position: bottom;
       
    }
    .FAQ_main>h2{
        font-size: 35px;
        color: var(--globalcolor1);
        margin-bottom: 40px;
        text-align: center;
    }
}
