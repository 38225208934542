.header_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 50px;
  align-items: center;
}
.logo {
  width: 15%;
}
.logo > a > img {
  width: 80%;
}
.pages {
  width: 50%;
}
.pages > ul {
  display: flex;
  flex-direction: row;
  gap: 50px;
  list-style: none;
  justify-content: center;
}
.pages > ul > li > a {
  color: var(--globalcolor5);
  text-decoration: none;
  font-weight: 600;
}
.login {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.login > a {
  text-decoration: none;
}
.login > a > h2 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 18px;
  color: var(--globalcolor5);
  text-decoration: none;
}
.login > a > button {
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
  background-color: var(--globalcolor2);
  color: var(--globalcolor4);
  border: 1px solid var(--globalcolor2);
  color: var(--globalcolor4);
  text-decoration: none;
}
.login > a > button:hover {
  background-color: var(--globalcolor5);
  color: var(--globalcolor4);
  border: 1px solid var(--globalcolor5);
  cursor: pointer;
}

.off-canvas {
  width: 40vw;
  position: fixed;
  background-color: var(--globalcolor5);
  top: 0px;
  right: 0px;
  height: 100vh;
  padding: 60px 100px;
  z-index: 2;
}
.off-canvas ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.off-canvas ul a {
  color: var(--globalcolor4);
  text-decoration: none;
  padding-bottom: 15px;
}
.off-canvas_header {
  display: flex;
  flex-direction: row;
  gap: 150px;
  align-items: top;
  justify-content: space-between;
  margin-bottom: 50px;
}
.off-canvas_logo > a > img {
  width: 100%;
  margin-bottom: 0px;
}
.cross {
  width: 40%;
}
.cross > svg {
  color: var(--globalcolor4);
  margin-top: 5px;
  cursor: pointer;
}
.menu {
  display: none;
}
.off-canvas h2 {
  color: var(--globalcolor4);
  margin: 50px 0px;
}
.off-canvas p {
  color: var(--globalcolor4);
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}
.off-canvas_login {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;
}
.off-canvas_login > svg {
  color: var(--globalcolor4);
}
@media (max-width: 1100px) {
  .header_main {
    display: flex;
    flex-direction: row;
    padding: 50px 30px;
    align-items: bottom;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .logo {
    width: 50%;
  }
  .logo > a > img {
    width: 100%;
  }
  .pages {
    display: none;
  }
  .pages > ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    justify-content: center;
  }
  .pages > ul > li > a {
    color: var(--globalcolor5);
    text-decoration: none;
    font-weight: 600;
  }
  .login {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }
  .login > h2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
    align-items: center;
  }
  .menu {
    width: 25%;
    display: flex;
    margin-left: 120px;
  }
  .off-canvas {
    width: 60vw;
    padding: 30px;
  }
  .off-canvas_logo > a > img {
    width: 150%;
    margin-bottom: 0px;
  }
}
@media (max-width: 650px) {
  .header_main {
    display: flex;
    flex-direction: row;
    padding: 30px 15px;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
  }
  .logo {
    width: 50%;
  }
  .logo > a > img {
    width: 100%;
  }
  .pages {
    width: 20%;
    display: none;
  }
  .pages > ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
  }
  .pages > ul > li > a {
    color: var(--globalcolor5);
    text-decoration: none;
    font-weight: 600;
  }
  .login {
    display: none;

    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    width: 80%;
  }
  .login > h2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
    align-items: center;
    display: none;
  }
  .login > button {
    display: none;
  }
  .menu {
    margin: 0px auto;
    width: 50%;
  }
  .menu > svg {
    position: relative;
    right: -130px;
  }
  .off-canvas {
    width: 80vw;
    padding: 20px;
    padding-left: 50px;
    padding-top: 30px;
  }
  .off-canvas_header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .off-canvas_logo > a > img {
    width: 100%;
    margin-bottom: 0px;
  }
}
