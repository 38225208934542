.contact_details {
  padding: 20px 120px;
}
.contact_details > h2 {
  text-align: center;
  font-size: 35px;
  color: var(--globalcolor1);
  margin-bottom: 15px;
  font-weight: 700;
}
.contact_details > p {
  text-align: center;
  font-size: 25px;
  color: var(--globalcolor7);

  line-height: 1.4;
}
.contact_boxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  padding: 30px 120px;
}
.contact_box {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  border: 1px solid var(--globalcolor3);
  gap: 20px;
  border-radius: 12px;
}
.contact_box > h3 {
  font-size: 20px;
  margin: 5px 0px;
  font-weight: 500;
  color: var(--globalcolor1);
}
.contact_box p {
  text-align: center;
}
.contact_secondsec h1 {
  font-size: 40px;
  color: var(--globalcolor1);
  font-weight: 600;
  text-align: center;
}
.contact_input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 50px 120px;
}
.name,
.mail,
.number,
.subject {
  padding: 20px;
  width: 49%;
  border: 0px;
  background-color: var(--globalcolor3);
  border-radius: 12px;
}
.textarea {
  padding: 20px;
  width: 100%;
  border: 0px;
  background-color: var(--globalcolor3);
  border-radius: 12px;
  height: 150px;
  display: flex;
  align-items: top;
}
.contact_input button {
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: var(--globalcolor4);
  background-color: var(--globalcolor2);
  border: 1px solid var(--globalcolor2);
  border-radius: 7px;
}
.contact_input button:hover {
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  color: var(--globalcolor4);
  background-color: var(--globalcolor5);
  cursor: pointer;
  border: 1px solid var(--globalcolor5);
  border-radius: 7px;
}
@media (max-width: 1100px) {
  .contact_boxContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    padding: 30px;
  }
  .contact_input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 50px 30px;
  }
  .name,
  .mail,
  .number,
  .subject {
    padding: 20px;
    width: 48.5%;
    border: 0px;
    background-color: var(--globalcolor3);
    border-radius: 12px;
  }
  .contact_details {
    padding: 20px 50px;
  }
}
@media (max-width: 650px) {
  .contact_boxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    padding: 30px;
  }
  .contact_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 30px;
    border: 1px solid var(--globalcolor3);
    gap: 20px;
    border-radius: 12px;
  }
  .contact_secondsec h1 {
    font-size: 30px;
    color: var(--globalcolor1);
    text-align: center;
  }
  .name,
  .mail,
  .number,
  .subject {
    padding: 20px;
    width: 100%;
    border: 0px;
    background-color: var(--globalcolor3);
    border-radius: 12px;
  }
  .contact_details {
    padding: 20px 30px;
  }
  .contact_details > h2 {
    font-size: 30px;
  }
  .contact_details > p {
    font-size: 17px;
  }
}
