.testimonial_box {
  display: flex;
  flex-direction: row;
  padding: 30px;
  width: 48%;
  gap: 20px;
  align-items: center;
  background-color: var(--globalcolor4);
  border-radius: 12px;
  color: var(--globalcolor10);
}
.testimonial_text > p {
  font-size: 22px;
  line-height: 1.6;
}
.testimonial_text > h4 {
  font-size: 20px;
  margin-top: 20px;
  color: var(--globalcolor1);
}
@media (max-width: 1100px) {
  .testimonial_box {
    display: flex;
    flex-direction: row;
    padding: 30px;
    width: 100%;
    gap: 20px;
    align-items: center;
    background-color: var(--globalcolor4);
    border-radius: 12px;
    color: var(--globalcolor10);
  }
  .testimonial_text > p {
    font-size: 22px;
  }
  .testimonial_text > h4 {
    font-size: 20px;
    margin-top: 20px;
    color: var(--globalcolor1);
  }
}
@media (max-width: 650px) {
  .testimonial_box {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    gap: 20px;
    align-items: center;
    background-color: var(--globalcolor4);
    border-radius: 12px;
    color: var(--globalcolor10);
  }
  .testimonial_text > p {
    font-size: 22px;
  }
  .testimonial_text > h4 {
    font-size: 20px;
    margin-top: 20px;
    color: var(--globalcolor1);
  }
}
