.pricingpage_second {
  background-image: url("https://whatsapp.hitoritech.com/assets/frontend/img/choose/choose-bg.png");
  background-repeat: no-repeat;
  background-position: 0%;
  background-size: cover;
  margin-top: 50px;
  padding: 100px 0px 150px;
  color: var(--globalcolor4);
  text-align: center;
}
.pricingpage_second > h1 {
  font-size: 52px;
}
.pricingpage_second > p {
  font-size: 20px;
  margin-top: 15px;
  font-weight: 500;
}
.pricingpage_imgcontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}
@media (max-width: 650px) {
  .pricingpage_second {
    padding: 100px 30px 250px;
  }
  .pricingpage_second > h1 {
    font-size: 35px;
  }
  .pricingpage_imgcontainer > img {
    width: 50%;
  }
  .pricingpage_container {
    margin-bottom: 50px;
  }
}
