.home_third_box {
  width: 50%;
  border: 1px solid var(--globalcolor3);
  padding: 50px;
}
.home_third_box > img {
  width: 80px;
}
.home_third_box > h3 {
  font-size: 28px;
  margin: 20px 0px;
  color: var(--globalcolor1);
}
.home_third_box > p {
  font-size: 16px;
  margin-bottom: 30px;
}
.home_third_box > a {
  color: var(--globalcolor1);
}
@media (max-width: 1100px) {
  .home_third_box {
    width: 50%;
    border: 1px solid var(--globalcolor3);
    padding: 50px;
  }
  .home_third_box > img {
    width: 80px;
  }
  .home_third_box > h3 {
    font-size: 28px;
    margin: 20px 0px;
  }
  .home_third_box > p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
@media (max-width: 650px) {
  .home_third_box {
    width: 100%;
    border: 1px solid var(--globalcolor3);
    padding: 18px;
  }
  .home_third_box > img {
    width: 80px;
  }

  .home_third_box > h3 {
    font-size: 28px;
    margin: 20px 0px;
  }
  .home_third_box > p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
