.featurepage_box{
    width: 48%;
    border: 1px solid var(--globalcolor3);
    padding: 50px;
    box-sizing: border-box;
}
.featurepage_box>img{
    width: 80px;
}
.featurepage_box>h3{
    font-size: 28px;
    margin: 20px 0px;
    color: var(--globalcolor1);
}
.featurepage_box>p{
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 24px;
}

@media (max-width: 1100px){
    .featurepage_box{
        width: 48%;
        border: 1px solid var(--globalcolor3);
        padding: 50px;
    }
    .featurepage_box>img{
        width: 80px;
    }
    .featurepage_box>h3{
        font-size: 28px;
        margin: 20px 0px;
    }
    .featurepage_box>p{
        font-size: 16px;
        margin-bottom: 30px;
    }
}
@media(max-width:650px){
    .featurepage_box{
        width: 100%;
        border: 1px solid var(--globalcolor3);
        padding: 18px;
    }
    .featurepage_box>img{
        width: 80px;
    }
    
    .feature_box>h3{
        font-size: 28px;
        margin: 20px 0px;
    }
    .feature_box>p{
        font-size: 16px;
        margin-bottom: 30px;
    }
}
