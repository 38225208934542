.team_box{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.team_box>img{
    width: 250px;
    border-radius: 12px;
}
.team_box>h3{
    color: var(--globalcolor4);
    font-size: 22px;
}
.team_box>p{
    color: var(--globalcolor11);
    font-size: 17px;
    margin-bottom: 5px;
}
.sociallinks>a>svg{
    color: var(--globalcolor4);
    
    
}

.sociallinks{
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
}
@media(max-width:1100px){
    .team_box{
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        width: 48%;
    }
}
@media(max-width:650px){
    .team_box{
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}