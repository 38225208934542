.footer {
  background-color: var(--globalcolor1);
  padding: 80px 30px 20px;
}
.footer > h2 {
  color: var(--globalcolor4);
  font-size: 52px;
  font-weight: 700;
  text-align: center;
}
.footer > p {
  color: var(--globalcolor4);
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0px;
  text-align: center;
}
.footer_btnContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}
.footer_btnContainer > a > button {
  padding: 12px 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  text-decoration: none;
  color: var(--globalcolor4);
  font-weight: 600;
  background-color: var(--globalcolor5);
  border: 1px solid var(--globalcolor5);
  cursor: pointer;
  border-radius: 7px;
  font-size: 16px;
}
.footer_main {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin: 50px;
  align-items: center;
  padding-top: 50px;
  border-top: 1px solid var(--globalcolor3);
}
.footer_logo {
  width: 15%;
}
.footer_logo > a > img {
  width: 100%;
}
.footer_pages {
  width: 50%;
}
.footer_pages > ul {
  display: flex;
  flex-direction: row;
  gap: 50px;
  list-style: none;
  justify-content: center;
}
.footer_pages > ul > li > a {
  color: var(--globalcolor4);
  text-decoration: none;
  font-weight: 600;
}
.footer_login {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.footer_login > a > svg {
  color: var(--globalcolor4);
}
.footer_login > a > svg:hover {
  cursor: pointer;
  opacity: 0.5;
}
@media (max-width: 1100px) {
  .footer {
    background-color: var(--globalcolor1);
    padding: 100px 30px 10px;
  }
  .footer > h2 {
    color: var(--globalcolor4);
    font-size: 42px;
    font-weight: 700;
    text-align: center;
  }

  .footer_main {
    display: flex;
    flex-direction: row;
    padding: 20px 30px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer_logo {
    width: 40%;
  }
  .footer_logo > img {
    width: 100%;
  }
  .footer_pages {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer_pages > ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer_pages > ul > li > a {
    color: var(--globalcolor4);
    text-decoration: none;
    font-weight: 600;
  }
  .footer_login {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
  }
  .footer_login > svg {
    color: var(--globalcolor4);
  }
  .login > svg:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}
@media (max-width: 650px) {
  .footer_main {
    display: flex;
    flex-direction: row;
    padding: 50px 20px 10px;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .footer_logo {
    width: 100%;
  }
  .footer_logo > img {
    width: 100%;
  }
  .footer > h2 {
    color: var(--globalcolor4);
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }
  .footer_pages {
    width: 60%;
    display: none;
  }
  .footer_pages > ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer_pages > ul > li > a {
    color: var(--globalcolor5);
    text-decoration: none;
    font-weight: 600;
  }
  .footer_login {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }
  .footer_login > svg {
    color: var(--globalcolor4);
  }
  .login > svg:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  .footer_btnContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-top: 44px;
  }
  .footer_btnContainer > a > button {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding: 12px 18px;
    font-size: 13px;
    color: var(--globalcolor4);
    font-weight: 600;
    background-color: var(--globalcolor5);
    border: 1px solid var(--globalcolor5);
    cursor: pointer;
    border-radius: 7px;
  }
}
