.home_pricingsec {
  padding: 0px 80px 50px;
}
.home_pricingsec > h2 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: var(--globalcolor1);
}
.home_pricingsec > p {
  font-size: 14px;
  margin: 30px 0px;
  text-align: center;
}
.pricing_container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 14px;
}
.pricing_box1 {
  width: 30%;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid var(--globalcolor3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.pricing_box2 {
  width: 30%;
  padding: 30px;
  margin-top: -30px;
  margin-bottom: -30px;
  border: 2px solid var(--globalcolor6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  border-radius: 8px;
}
.pricing_box3 {
  width: 30%;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid var(--globalcolor3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.pricing_container p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pricelist > ul {
  padding: 0, 7px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  list-style-type: disc;
}
.pricing_box1 > h2 {
  font-size: 25px;
  line-height: 1em;
  font-weight: 600;
  text-align: center;
  color: var(--globalcolor1);
}
.pricing_box1 h2 > span {
  font-size: 14px;
  line-height: 1em;
}

.pricing_btncontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pricing_btncontainer1 > a > button {
  padding: 12px 30px;
  font-size: 14px;
  background-color: var(--globalcolor9);
  border: 1px solid var(--globalcolor9);
  width: 100%;
  color: var(--globalcolor4);
  border-radius: 8px;
  text-decoration: none;
}

.pricing_btncontainer1 > a > button:hover {
  background-color: var(--globalcolor5);
  border: 1px solid var(--globalcolor5);
  cursor: pointer;
}
.pricing_box2 > h2 {
  font-size: 25px;
  line-height: 1em;
  font-weight: 600;
  color: var(--globalcolor1);
}

.pricing_box2 h2 > span {
  font-size: 14px;
  line-height: 1em;
}

.pricing_box3 h2 > span {
  font-size: 14px;
  line-height: 1em;
}

.pricing_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 80px;
}
.pricing_btncontainer2 > a > button {
  padding: 12px 30px;
  font-size: 14px;
  background-color: var(--globalcolor6);
  border: 1px solid var(--globalcolor6);
  color: var(--globalcolor4);
  width: 100%;
  text-decoration: none;
  border-radius: 8px;
}
.pricing_btncontainer2 > a > button:hover {
  background-color: var(--globalcolor5);
  border: 1px solid var(--globalcolor5);
  cursor: pointer;
}
.pricing_btncontainer3 > a > button {
  padding: 12px 30px;
  font-size: 14px;
  background-color: var(--globalcolor9);
  border: 1px solid var(--globalcolor9);
  width: 100%;
  color: var(--globalcolor4);
  border-radius: 8px;
  text-decoration: none;
}

.pricing_btncontainer3 > a > button:hover {
  background-color: var(--globalcolor5);
  border: 1px solid var(--globalcolor5);
  cursor: pointer;
}
.pricing_box3 > h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 1em;
  color: var(--globalcolor1);
}
@media (max-width: 1100px) {
  .home_pricingsec {
    padding: 50px;
  }
  .pricing_box1 {
    width: 45%;
    padding: 30px;
    border-radius: 8px;
    border: 1px solid var(--globalcolor3);
  }
  .pricing_box2 {
    width: 45%;
    padding: 30px;
    margin-bottom: 0px;
    border: 2px solid var(--globalcolor6);
    border-radius: 8px;
  }
  .pricing_box3 {
    width: 45%;
    padding: 30px;
    border-radius: 8px;
    border: 1px solid var(--globalcolor3);
  }
  .pricing_container {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media (max-width: 650px) {
  .home_pricingsec {
    padding: 80px 30px;
  }
  .pricing_box1 {
    width: 100%;
    padding: 30px;
    border-radius: 8px;
    border: 1px solid var(--globalcolor3);
    margin-top: 30px;
  }
  .pricing_box2 {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 0px;
    border: 2px solid var(--globalcolor6);
    border-radius: 8px;
  }
  .pricing_box3 {
    width: 100%;
    padding: 30px;
    border-radius: 8px;
    border: 1px solid var(--globalcolor3);
    margin-top: 30px;
  }
  .pricing_container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .home_pricingsec > h2 {
    font-size: 39px;
    line-height: 40px;
    text-align: center;
    color: var(--globalcolor1);
  }
}
