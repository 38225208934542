.pagenotfound{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    height: 100vh;
    background-color: var(--globalcolor3);
    padding-top: 200px;
}
.pagenotfound>h1{
    font-size: 72px;
    color: var(--globalcolor1);
}
.pagenotfound p{
    color: var(--globalcolor7);
    width: 500px;
    text-align: center;
}
.pagenotfound a button{
    padding: 12px 20px;
    background-color: var(--globalcolor1);
    border: 1px solid var(--globalcolor1);
    border-radius: 12px;
    margin-top: 20px;
    text-decoration: none;
    color: var(--globalcolor4);
    font-size: 18px;
}
@media(max-width:650px){
    .pagenotfound p{
        color: var(--globalcolor7);
        width: 300px;
        text-align: center;
    } 
}