.aboutpage_firstsec{
    padding: 30px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.aboutpage_firstsec>p{
    font-size: 16px;
    font-weight: 500;
    color: var(--globalcolor7);
    margin-bottom: 20px;
}
.aboutpage_firstsec>h2{
    font-size: 45px;
    color: var(--globalcolor1);
    width: 800px;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.2;
}
.aboutpage_firstsecboxcont{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
}
.aboutpage_firstleftbox{
    width: 49%;
 
}
.aboutimg1{
    width: 50%;
}
.aboutimg2{
    width: 50%;
}
.aboutpage_firstrightbox{
    width: 49%;
}
.aboutpage_firstrightbox>p{
    font-size: 18px;
    color: var(--globalcolor7);
    font-weight: 400;
    line-height: 1.6;
}
.aboutfirst_rightbox_feature{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.aboutfirst_rightbox_feature>p{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-start;
    margin-bottom: 12px;
    font-size: 17px;
    color: var(--globalcolor1);

}


.aboutpage_firstrightbox>a>svg{
    margin-top: 20px;
    color: var(--globalcolor5);
}
.aboutpage_secondsec{
    margin: 20px 50px;
    padding: 100px 80px;
    background-color: var(--globalcolor3);
    border-radius: 20px;
    margin-bottom: 100px;
}
.aboutpage_secondsecfirst{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.aboutpage_secondsecfirst>h2{
    font-size: 42px;
    color: var(--globalcolor1);
}
.aboutpage_secondsecfirst>a>button{
    padding: 15px 35px;
    border: 1px solid var(--globalcolor2);
    background-color: var(--globalcolor2);
    color: var(--globalcolor4);
    font-weight: 500;
    font-size: 17px;
    border-radius: 7px;
}
.aboutpage_secondsecfirst>a>button:hover{
    border: 1px solid var(--globalcolor5);
    cursor: pointer;
    background-color: var(--globalcolor5);
}
.about_features{
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}
.about_feature1,.about_feature2{
    width: 48%;
    background-color: var(--globalcolor4);
    border-radius: 10px;
    padding: 50px;
}
.about_feature1>img,.about_feature2>img{
    width: 60px;
}
.about_feature1>h2,.about_feature2>h2{
    font-size: 25px;
    color: var(--globalcolor1);
    margin: 30px 0px;
}
.about_feature1>p,.about_feature2>p{
    font-size: 16px;
    color: var(--globalcolor1);
    margin: 30px 0px;
    line-height: 24px;
}

.about_clientssec{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 80px;
    border-top: 1px solid var(--globalcolor11);
    padding-top: 50px;
}
.about_clients{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.about_clientsectext>h2{
    font-size: 40px;
    margin-bottom: 2px;
    color: var(--globalcolor1);
}
.about_lastsection{
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 80px 120px;
}
.about_lastsectionleft{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.about_lastsectionright{
    width: 40%;
}
.about_textcontainer{
    width: 100%;
    padding: 15px;
    border: 1px solid var(--globalcolor11);
    border-radius: 12px;
    cursor: pointer;
}
.about_activetext{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
    color: var(--globalcolor1);
    margin-bottom: 15px;
    text-align: center;
}
.about_text{
    line-height: 1.8;
}
.about_lastsec_box{
    width: 100%;
    background-color: var(--globalcolor3);
    padding: 70px 30px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about_lastsec_box>svg{
    border: 2px solid var(--globalcolor4);
 background-color: var(--globalcolor4);
 padding: 15px;
 border-radius: 50%;
 margin-bottom: 20px;
}
.about_lastsec_box h3{
    color: var(--globalcolor1);
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
}
.about_lastsec_box p{
    text-align: center;
    color: var(--globalcolor7);
    font-size: 18px;
    margin-bottom: 50px;
    line-height: 1.8;
}
.about_lastsec_box>button{
    padding: 20px;
    width: 90%;
    font-size: 16px;
    border-radius: 10px;
    color: var(--globalcolor4);
    background-color: var(--globalcolor12);
    border: 1px solid var(--globalcolor12);
}
.about_lastsec_box>button>a{
    color: var(--globalcolor4);
    text-decoration: none;
}
.about_lastsec_box>button:hover{
    padding: 20px;
    width: 90%;
    font-size: 16px;
    color: var(--globalcolor4);
    background-color: var(--globalcolor2);
    border: 1px solid var(--globalcolor2);
    cursor: pointer;
}

.about_work{
    padding: 80px 130px;
    margin-top: 30px;
    background-color: var(--globalcolor4);
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.aboutwork_leftcon{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.aboutwork_rightcon{
    width: 50%;
    display: flex;
    align-items: center;
}
.aboutwork_rightcon>img{
    width: 100%;
    
}
.aboutwork_leftcon>h2{
    color: var(--globalcolor1);
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    
}
.aboutwork_leftcon>p>strong{
    color: var(--globalcolor7);
    font-size: 20px;
    line-height: 30px;
    
    
}
.aboutwork_leftcon>p{
    color: var(--globalcolor7);
    font-size: 20px;
    line-height: 30px;   
}

.aboutwork_leftconbutton>a>button{
    font-size: 18px;
    padding: 12px 0px 7px;
    background-color: var(--globalcolor16);
    border: 0px ;
    border-bottom: 2px solid var(--globalcolor6);
    color: var(--globalcolor1);
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.aboutwork_leftcon>strong{
    color: var(--globalcolor1);
    font-size: 20px;
    border-left: 5px solid var(--globalcolor6);
    padding-left: 15px;
    
    
}

@media(max-width:1100px){
    .aboutpage_firstsec{
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }
    .aboutpage_firstsec>h2{
        font-size: 38px;
        color: var(--globalcolor1);
        width: 620px;
        text-align: center;
        margin-bottom: 40px;
    }
    .aboutpage_firstsecboxcont{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
    .aboutpage_firstleftbox{
        width: 100%;
     
    }
    .aboutpage_firstrightbox{
        width: 100%;
    }
    .aboutpage_secondsec{
        margin: 20px 0px;
        padding: 100px 50px;
        background-color: var(--globalcolor3);
        border-radius: 20px;
        margin-bottom: 50px;
    }
    .about_features{
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
    .about_feature1,.about_feature2{
        width: 50%;
        background-color: var(--globalcolor4);
        border-radius: 10px;
        padding: 80px 50px;
    }
    .about_feature1>img,.about_feature2>img{
        width: 60px;
    }
    .about_feature1>h2,.about_feature2>h2{
        font-size: 25px;
        color: var(--globalcolor1);
        margin: 30px 0px;
    }
    .about_feature1>p,.about_feature2>p{
        font-size: 16px;
        color: var(--globalcolor1);
        margin: 30px 0px;
    }
    .about_clientssec{
        display: flex;
        flex-direction: row;
        gap: 25px;
        margin-top: 80px;
        border-top: 1px solid var(--globalcolor11);
        padding-top: 50px;
        flex-wrap: wrap;
    }
    .about_clients{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: 48%;
    }
    .about_clientsectext>h2{
        font-size: 40px;
        margin-bottom: 2px;
        color: var(--globalcolor1);
    }

.about_lastsection{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 100px 80px;
}
.about_lastsectionleft{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
    .about_lastsectionright{
        width: 100%;
    }

    .about_work{
        padding: 50px;
        margin-top: 30px;
        background-color: var(--globalcolor4);
        display: flex;
        flex-direction: row;
        gap: 50px;
        flex-wrap: wrap;
    }
    .aboutwork_leftcon{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .aboutwork_rightcon{
        width: 100%;
        display: flex;
        align-items: center;
    }
}
@media (max-width:650px){
    
    .aboutpage_imgcontainer>img{
        width: 50%;
    }
    .aboutpage_firstsec{
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }
    .aboutpage_firstsec>h2{
        font-size: 34px;
        color: var(--globalcolor1);
        width: 320px;
        text-align: center;
        margin-bottom: 40px;
    }
    .aboutpage_firstsecboxcont{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
    .aboutpage_firstleftbox{
        width: 100%;
     
    }
    .aboutpage_firstrightbox{
        width: 100%;
    }
    .aboutpage_secondsec{
        margin: 20px 0px;
        padding: 100px 20px;
        background-color: var(--globalcolor3);
        border-radius: 20px;
    }
    .aboutpage_secondsecfirst{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }
    .aboutpage_secondsecfirst>h2{
        font-size: 42px;
        color: var(--globalcolor1);
    }
    .about_features{
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
    .about_feature1,.about_feature2{
        width: 100%;
        background-color: var(--globalcolor4);
        border-radius: 10px;
        padding: 80px 50px;
    }
    .about_feature1>img,.about_feature2>img{
        width: 60px;
    }
    .about_feature1>h2,.about_feature2>h2{
        font-size: 25px;
        color: var(--globalcolor1);
        margin: 30px 0px;
    }
    .about_feature1>p,.about_feature2>p{
        font-size: 16px;
        color: var(--globalcolor1);
        margin: 30px 0px;
    }
    .about_clientssec{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 80px;
        border-top: 1px solid var(--globalcolor11);
        padding-top: 50px;
        flex-wrap: wrap;
        align-items: center;
    }
    .about_clients{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        width: 100%;
    }
    .about_clientsectext>h2{
        font-size: 40px;
        margin-bottom: 2px;
        color: var(--globalcolor1);
    }
    
.about_lastsection{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 100px 20px;
}
.about_lastsectionleft{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
    .about_lastsectionright{
        width: 100%;
    }
    .about_activetext{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 700;
        color: var(--globalcolor1);
        margin-bottom: 15px;
        text-align: left;
    }
    .about_textcontainer{
        width: 100%;
        padding: 10px;
        border: 1px solid var(--globalcolor11);
        border-radius: 12px;
        cursor: pointer;
    }
    .about_work{
        padding: 50px 30px;
        margin-top: 30px;
        background-color: var(--globalcolor4);
        display: flex;
        flex-direction: row;
        gap: 50px;
        flex-wrap: wrap;
    }
}

