.header2_main{
    display: flex;
    flex-direction: row;
   justify-content: space-between;
    padding: 50px;
    align-items: center;
 
}
.header2_logo{
    width: 15%;
}
.header2_logo>a>img{
    width: 80%;
}
.header2_pages{
    width: 50%;
}
.header2_pages>ul{
    display: flex;
    flex-direction: row;
    gap: 50px;
    list-style: none;
    justify-content: center;
}
.header2_pages>ul>li>a{
    color: var(--globalcolor5);
    text-decoration: none;
    font-weight: 600;
}
.header2_login{
    display: flex;
    flex-direction: row;
    gap: 50px;
   align-items: center;
   justify-content: center;
}
.header2_login>h2{
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 18px;
    display: none;
}
.header2_login>a>button{
    padding: 15px 40px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    background-color: var(--globalcolor2);
    color: var(--globalcolor4);
    border: 1px solid var(--globalcolor2);
    color: var(--globalcolor4);
    text-decoration: none;
}
.header2_login>a>button:hover{
    background-color: var(--globalcolor5);
    color: var(--globalcolor4);
    border: 1px solid var(--globalcolor5);
    cursor: pointer;
}


.header2_off-canvas{
    width: 40vw;
    position:fixed;
    background-color: var(--globalcolor5);
    top: 0px;
    z-index: 2;
    right: 0px;
    height: 100vh;
    padding: 60px 100px;
}
.header2_off-canvas ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.header2_off-canvas ul a{
   color: var(--globalcolor4);
   text-decoration: none;
padding-bottom: 15px;

}
.header2_off-canvas_header{
    display: flex;
    flex-direction: row;
    gap: 150px;
    align-items: top;
    justify-content: space-between;
    margin-bottom: 50px;
}
.header2_off-canvas_logo>a>img{
    width: 100%;
    margin-bottom: 0px;
 
}
.header2_cross{
    width: 40%;
}
.header2_cross>svg{
   color: var(--globalcolor4);
   margin-top: 5px;
   cursor: pointer;
}
.header2_menu{
    display: none;
}
.header2_off-canvas h2{
    color: var(--globalcolor4);
    margin: 50px 0px;
}
.header2_off-canvas p{
    color: var(--globalcolor4);
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
}
.header2_off-canvas_login{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 50px;
}
.header2_off-canvas_login>svg{
    color: var(--globalcolor4);
}
.header2_login button a{
    color: white;
    text-decoration: none;
}
@media (max-width: 1100px){
    .header2_main{
        display: flex;
        flex-direction: row;
        padding: 50px 30px;
        align-items: bottom;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .header2_logo{
        width: 50%;
    }
    .header2_logo>a>img{
        width: 100%;
    }
    .header2_pages{
      
        display: none;
    }
    .header2_pages>ul{
        display: flex;
        flex-direction: row;
        gap: 20px;
        list-style: none;
        justify-content: center;
    }
    .header2_pages>ul>li>a{
        color: var(--globalcolor5);
        text-decoration: none;
        font-weight: 600;
    }
    .header2_login{
        width: 80%;
        display: flex;
        flex-direction: row;
        gap: 20px;
       align-items: center;
       justify-content: center;
       margin-top: 0px;
    }
    .header2_login>h2{
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 16px;
        align-items: center;
        display: none;
    }
    .header2_login>a>button{
        margin-left: 100px;
        padding: 15px 30px;
    }
    .header2_login>a>button:hover{
        background-color: var(--globalcolor5);
        border: 1px solid var(--globalcolor5);
        cursor: pointer;
        padding: 15px 30px;
       
    }
    .header2_menu{
        width: 25%;
        display:flex;
      margin-left: 180px;
    }
    .header2_off-canvas{
        width: 60vw;
        padding: 30px;
    }
    .header2_off-canvas_logo>a>img{
        width: 150%;
        margin-bottom: 0px;
     
    }
}
@media (max-width: 650px){
    .header2_main{
        display: flex;
        flex-direction: row;
         padding: 30px 15px;
        align-items: center;
        flex-wrap: nowrap;
        gap: 20px;
     
    }
    .header2_logo{
        width: 50%;
    }
    .header2_logo>a>img{
        width: 100%;
    }
    .header2_pages{
        width: 20%;
        display: none;
    }
    .header2_pages>ul{
        display: flex;
        flex-direction: row;
        gap: 20px;
        list-style: none;
        justify-content: center;
        flex-wrap: wrap;
    }
    .header2_pages>ul>li>a{
        color: var(--globalcolor5);
        text-decoration: none;
        font-weight: 600;
    }
    .header2_login{
        display: none;
       
        flex-direction: row;
        gap: 20px;
       align-items: center;
       justify-content: center;
       margin-top: 0px;
       width: 80%;
    }
    .header2_login>h2{
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 16px;
        align-items: center;
        display: none;
    }
    .header2_login>button{
        display: none;
    }
    .header2_menu{
        margin: 0px auto;
        width: 50%;
    }
  .header2_menu>svg{
    position: relative;
    right: -130px;
  }
  .header2_off-canvas{
    width: 80vw;
    padding: 20px;
    padding-left: 50px;
    padding-top: 30px;
}
.header2_off-canvas_header{
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}
.header2_off-canvas_logo>a>img{
  width: 100%;
    margin-bottom: 0px;
}
}