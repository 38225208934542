.team_main{
    padding: 100px;
    margin: 50px;
    background-color: var(--globalcolor13);
    border-radius: 20px;
}
.team_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.team_maintext>h2{
    font-size: 42px;
    color: var(--globalcolor4);
}
.team_buttoncontainer{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.button1{
    padding: 15px 25px;
    background-color: var(--globalcolor14);
    font-size: 16px;
    font-weight: 500;
    color: var(--globalcolor4);
    border: 1px solid var(--globalcolor14);
    cursor: pointer;
    border-radius: 7px;

}
.button1:hover{
    background-color: var(--globalcolor5); 
    color: var(--globalcolor4);
    border: 1px solid var(--globalcolor5);
    cursor: pointer;
}
.button2{
    padding: 15px 25px;
    background-color: var(--globalcolor6);
    font-size: 16px;
    font-weight: 500;
    color: var(--globalcolor4);
    border: 1px solid var(--globalcolor6);
    cursor: pointer;
    border-radius: 7px;
}
.button2:hover{
  
    background-color: var(--globalcolor5);
   
    color: var(--globalcolor4);
    border: 1px solid var(--globalcolor5);
    cursor: pointer;
}
.teamimagecontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}
@media(max-width:1100px){
    .team_main{
        padding: 100px 80px;
        margin: 0px;
        background-color: var(--globalcolor13);
        border-radius: 20px;
    }
    .team_container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;

    }
    .team_maintext{
        width: 100%;
    }
    .team_buttoncontainer{
        width: 100%;
    }
    .teamimagecontainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        flex-wrap: wrap;
        gap: 20px;
    }
}
@media(max-width:650px){
    .team_main{
        padding: 100px 20px;
        margin: 0px;
        background-color: var(--globalcolor13);
        border-radius: 20px;
    }
    .team_maintext>h2{
        font-size: 35px;
        color: var(--globalcolor4);
    }
    .team_container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;

    }
    .team_maintext{
        width: 100%;
    }
    .team_buttoncontainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .teamimagecontainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        flex-wrap: wrap;
        gap: 20px;
    }
}