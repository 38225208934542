.customers_main {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    padding: 0px 100px;
    margin-top: -80px;
}

.customers_box {
    width: 30%;
    border: 1px solid var(--globalcolor11);
    border-radius: 15px;
    background-color: var(--globalcolor3);
    padding: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.customers_box:hover {
    width: 30%;
    border: 1px solid var(--globalcolor4);
    border-radius: 15px;
    background-color: var(--globalcolor4);
    padding: 50px;
}

.customers_text>h2 {
    font-size: 48px;
    text-align: left;
    margin-bottom: 0px;
    font-weight: 700;
}

.customers_box img {
    width: 70%;
}

.customers_img {
    width: 40%;
}

.customers_text {
    width: 60%;
    display: flex;
    flex-direction: column;
}
.customers_text>p{
    font-size: 18px;
}
@media(max-width:1100px){

    .customers_main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding: 0px 50px;
        margin-top: -80px;
    }
    .customers_box {
    width: 100%;
    border: 1px solid var(--globalcolor9);
    border-radius: 15px;
    background-color: var(--globalcolor3);
    padding: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.customers_box:hover {
    width: 100%;
    border: 1px solid var(--globalcolor4);
    border-radius: 15px;
    background-color: var(--globalcolor4);
    padding: 50px;
}
.customers_img {
    width: 20%;
}
.customers_img>img {
    width: 108px;
    height: 108px;
}
}
@media (max-width: 650px){
    .customers_main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding: 0px 20px;
        margin-top: -200px;
    }
    .customers_box {
        width: 100%;
        border: 1px solid var(--globalcolor9);
        border-radius: 15px;
        background-color: var(--globalcolor3);
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }
    .customers_box:hover {
        width: 100%;
        border: 1px solid var(--globalcolor4);
        border-radius: 15px;
        background-color: var(--globalcolor4);
        padding: 20px;
    }
    .customers_img {
        width: 30%;
    }
    .customers_img>img {
        width: 88px;
        height: 88px;
    }
    .customers_text>h2 {
        font-size: 32px;
        text-align: left;
        margin-bottom: 0px;
        font-weight: 700;
    }
    .customers_text>p{
        font-size: 16px;
    }
}