:root {
  --padding-left-right-main: 10px 50px 225px;
  --globalcolor1: #3a415c;
  --globalcolor2: #656e83;
  --globalcolor3: #b2b6c1;
  --globalcolor4: white;
  --globalcolor5: black;
  --globalcolor6: #3a415c;
  --globalcolor7: #626392;
  --globalcolor8: #3a415c;
  --globalcolor9: #656e83;
  --globalcolor10: #3a415c;
  --globalcolor11: #d3e5f1;
  --globalcolor12: #3a415c;
  --globalcolor13: #3c096c;
  --globalcolor14: #5a189a;
  --globalcolor15: #7b2cbf;
  --globalcolor16: transparent;
  --globalcolor17: #656e83;
  --globalcolor18: #d1d1d1;
  --globalcolor19: #f1efff;
  --globalcolor20: #b08ff7;
  --globalcolor21: #7564fc;
  --globalcolor22: #707783;
  --globalcolor23: #38c0d7;
  --globalcolor24: #b2b6c1;
  --globalcolor25: #1dbfda;
  --home2body: #10161f;
  --section1: #0e121a;
  --section1-hover: #5285da;
  --section3-box: rgba(14, 18, 26, 0.5);
  --section4-body: #0e121a;
  --section4-box: #1d2838;
  --section5box-hover: #131922;
}
a {
  text-decoration: none;
  outline: none;
}
.home_Container {
  background-image: url("https://whatsapp.hitoritech.com/assets/frontend/img/hero/hero-bg-2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.home_firstsec {
  padding: var(--padding-left-right-main);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home_firstsec > h1 {
  font-size: 62px;
  font-weight: 700;
  color: var(--globalcolor1);
  width: 60%;
  text-align: center;
  line-height: 1.2;
}

.home_firstsec span {
  font-size: 62px;
  color: var(--globalcolor2);
  width: 60%;
  text-align: center;
  line-height: 80px;
}

.home_btncontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;
}
.home_btncontainer a .home_first_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 15px 20px;
  background-color: var(--globalcolor5);
  color: var(--globalcolor4);
  font-size: 18px;
  font-weight: 500;
  border: 1px solid var(--globalcolor5);
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
}
.home_btncontainer a .home_second_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 15px 20px;
  background-color: var(--globalcolor2);
  color: var(--globalcolor4);
  font-size: 18px;
  font-weight: 500;
  border: 1px solid var(--globalcolor2);
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
}
.home_firstsec p {
  margin-top: 20px;
}

.p_span > span {
  font-size: 16px;
  font-weight: 600;
  color: var(--globalcolor1);
}

.home_imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_imgContainer > video {
  width: 60%;
  margin-top: -180px;
}

.home_imgContainer > h3 {
  margin: 100px 0px;
}

.home_secondsec {
  margin: 20px 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--globalcolor3);
  border-radius: 15px;
}

.home_Secondsec_left {
  width: 50%;
  padding: 0px 80px;
}

.home_Secondsec_left > h2 {
  font-size: 36px;
  font-weight: 700;
  color: var(--globalcolor1);
  line-height: 1.2;
}

.home_Secondsec_left > img {
  margin-top: -200px;
}

.home_Secondsec_right {
  width: 50%;
  padding: 0px 80px;
  display: flex;
  justify-content: center;
}

.home_Secondsec_right > img {
  margin-bottom: 0px;
  border-radius: 12px;
  width: 90%;
}

.home_thirdsec {
  padding: 30px;
}

.home_thirdsec > h2 {
  font-size: 52px;
  font-weight: 600;
  text-align: center;
  color: var(--globalcolor1);
}

.home_third_boxcontainer {
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: left;
  padding: 50px;
}

.home_forthsec {
  padding: 80px 150px 15px;
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 0px;
}

.home_forthsec_box1 {
  width: 60%;
}

.home_forthsec_box2 {
  width: 50%;
}

.forthsec_boximg {
  z-index: 2;
}

.forthsec_boximg1 {
  position: relative;
  bottom: 200px;
  right: 40px;
  z-index: 1;
}

.forthsec_boximg2 {
  position: relative;
  bottom: 200px;
  left: -100px;
  z-index: 1;
}

.home_forthsec_box2 > h2 {
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 30px;
  color: var(--globalcolor1);
}

.home_forthsec_box2 > p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 30px;
}

.home_forthsec_box2 > a > button {
  padding: 15px 40px;
  font-size: 15px;
  border: 1px solid var(--globalcolor2);
  background-color: var(--globalcolor2);
  color: var(--globalcolor4);
  font-weight: 600;
  border-radius: 30px;
  text-decoration: none;
}

.home_forthsec_box2 > a > button:hover {
  padding: 15px 40px;
  font-size: 15px;
  border: 1px solid var(--globalcolor5);
  background-color: var(--globalcolor5);
  color: var(--globalcolor4);
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
}

.home_fifthsec {
  padding: 120px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 50px;
}

.home_fifthleft {
  width: 50%;
  padding-right: 100px;
  box-sizing: border-box;
}

.home_fifthleft > h2 {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--globalcolor1);
}

.home_fifthleft h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 20px 0px 5px;
}

.home_fifthleft p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.home_inputcontainer {
  display: flex;
  flex-direction: row;
}

.home_inputcontainer input {
  width: 80%;
  margin-top: 20px;
  padding: 5px 0px 10px;
  border-bottom: 1px solid var(--globalcolor5);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 18px;
  color: var(--globalcolor5);
  text-decoration: none;
}

.home_inputcontainer > a > button {
  width: 100%;
  padding: 5px 0px 0px;
  border-bottom: 1px solid var(--globalcolor5);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 16px;
  background-color: var(--globalcolor16);
  text-align: right;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.home_fifthright {
  width: 50%;
}

.home_fifthleft h4 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
}

.home_fifthimgcontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
}

.home_fifthimg1 {
  left: 120px;
}

.home_fifthimg2 {
  position: relative;
  right: 450px;
  bottom: 280px;

  border-radius: 12px;
}

.home_fifthimg3 {
  position: relative;
  left: 200px;
  bottom: 50px;

  border-radius: 12px;
}

.home_testimonial {
  padding: 80px 30px 250px;
  background-color: var(--globalcolor3);
  margin-top: 0px;
}

.home_testimonial > h2 {
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 80px;
  color: var(--globalcolor1);
  line-height: 1.2;
}

.home_Testimonial_main {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.home_testimonial_btn {
  display: flex;
  flex-direction: row;
  gap: 800px;
  position: absolute;
  left: 200px;
  top: 200px;
}
.home_testimonial_btn > img {
  width: 50px;
}

.home_lastsecond {
  background-color: var(--globalcolor1);
  padding: 100px;
  margin: 0px 120px;
  margin-top: -150px;
  border-radius: 20px;
  position: relative;
}
.home_lastsecond > h2 {
  color: var(--globalcolor4);
  font-size: 55px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
}
.shape1 {
  position: absolute;
  top: 14%;
  left: 18%;
}
.shape2 {
  position: absolute;
  bottom: 14%;
  left: 8%;
}
.shape3 {
  position: absolute;
  bottom: 18%;
  left: 18%;
}
.shape4 {
  position: absolute;
  bottom: 14%;
  right: 8%;
}
.shape5 {
  position: absolute;
  top: 14%;
  right: 8%;
}
.home_lastsecondbtn {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: center;
}
.home_lastsecondbtn > a > button {
  padding: 15px 25px;
  border: 1px solid var(--globalcolor2);
  border-radius: 10px;
  background-color: var(--globalcolor2);
  color: var(--globalcolor4);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}
.home_lastsecondbtn > a > button:hover {
  border: 1px solid var(--globalcolor5);
  background-color: var(--globalcolor5);
}
.home_lastsection {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 100px 120px;
}
.home_lastsec_box {
  width: 100%;
  background-color: var(--globalcolor3);
  padding: 80px 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home_lastsec_box > svg {
  border: 2px solid var(--globalcolor4);
  background-color: var(--globalcolor4);
  padding: 15px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.home_lastsec_box h3 {
  color: var(--globalcolor1);
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}
.home_lastsec_box p {
  text-align: center;
  color: var(--globalcolor1);
  font-size: 18px;
  margin-bottom: 50px;
  line-height: 1.8;
}
.home_lastsec_box a {
  width: 90%;
}
.home_lastsec_box a > button {
  padding: 20px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  color: var(--globalcolor4);
  background-color: var(--globalcolor8);
  border: 1px solid var(--globalcolor8);
  text-decoration: none;
}

.home_lastsec_box > a > button:hover {
  background-color: var(--globalcolor2);
  border: 1px solid var(--globalcolor2);
  cursor: pointer;
}
@media (max-width: 1280px) {
  .home_firstsec {
    padding: 10px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home_firstsec > h1 {
    font-size: 52px;
    color: #141774;
    width: 100%;
    text-align: center;
    line-height: 60px;
  }

  .home_firstsec span {
    font-size: 52px;
    color: var(--globalcolor2);
    width: 100%;
    text-align: center;
    line-height: 60px;
  }

  .home_imgContainer > video {
    width: 90%;
    margin-top: 0px;
  }

  .home_imgContainer > h3 {
    margin: 100px 0px;
  }

  .p_span > span {
    font-size: 18px;
    font-weight: 600;
    color: var(--globalcolor1);
  }

  .home_secondsec {
    margin: 20px 60px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background: var(--globalcolor4);
    border-radius: 15px;
    padding: 0px 50px 20px;
  }

  .home_Secondsec_left {
    width: 100%;
    padding: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: var(--globalcolor3);
  }

  .home_Secondsec_left > h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
  }

  .home_Secondsec_left > img {
    margin-top: 0px;
    display: none;
  }

  .home_Secondsec_right {
    width: 100%;
    padding: 0px 80px;
    display: flex;
    justify-content: center;

    margin-bottom: 15px;
  }

  .home_Secondsec_right > img {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .home_thirdsec > h2 {
    font-size: 38px;
    text-align: center;
    color: var(--globalcolor1);
  }

  .home_forthsec {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: top;
    gap: 0px;
  }

  .home_forthsec_box1 {
    width: 100%;
  }

  .forthsec_boximg {
    width: 100%;
  }

  .home_forthsec_box2 {
    width: 100%;
  }

  .forthsec_boximg1 {
    position: relative;
    bottom: 800px;
    left: 500px;
  }

  .forthsec_boximg2 {
    position: relative;
    bottom: 50px;
    left: -200px;
  }

  .home_fifthsec {
    padding: 50px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .home_fifthleft {
    width: 100%;
    padding-right: 80px;
  }

  .home_fifthleft > h2 {
    font-size: 48px;
    line-height: 50px;
  }

  .home_fifthleft h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0px 5px;
  }

  .home_fifthleft p {
    font-size: 20px;
    font-weight: 400;
  }

  .home_inputcontainer {
    display: flex;
    flex-direction: row;
  }

  .home_inputcontainer input {
    width: 80%;
    margin-top: 20px;
    padding: 5px 0px 10px;
    border-bottom: 1px solid var(--globalcolor5);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 18px;
    color: var(--globalcolor5);
  }

  .home_inputcontainer > button {
    width: 20%;
    padding: 5px 0px 0px;

    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 16px;

    text-align: right;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .home_fifthright {
    width: 100%;
    margin-top: 80px;
  }

  .home_fifthleft h4 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
  }

  .home_fifthimgcontainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
  }

  .home_fifthimg1 {
    position: absolute;
    left: 117px;
  }

  .home_fifthimg2 {
    position: relative;
    right: 0px;
    bottom: 150px;

    border-radius: 12px;
  }

  .home_fifthimg3 {
    position: relative;
    left: 45px;
    bottom: -225px;

    border-radius: 12px;
  }

  .home_testimonial {
    padding: 80px 30px 250px;
    background-color: var(--globalcolor3);
    margin-top: 200px;
  }
  .home_lastsecond {
    background-color: var(--globalcolor1);
    padding: 80px 30px;
    margin: 0px 50px;
    margin-top: -150px;
    border-radius: 20px;
  }
  .home_lastsection {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 100px 80px;
  }
  .home_lastsec_box {
    width: 100%;
    background-color: var(--globalcolor3);
    padding: 80px 30px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .home_firstsec {
    padding: 10px 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home_firstsec > h1 {
    font-size: 38px;
    color: var(--globalcolor1);
    width: 100%;
    text-align: center;
    line-height: 50px;
  }

  .home_firstsec span {
    font-size: 38px;
    color: var(--globalcolor2);
    width: 100%;
    text-align: center;
    line-height: 60px;
  }

  .home_imgContainer > video {
    width: 90%;
    margin-top: 0px;
  }

  .home_imgContainer > h3 {
    margin: 100px 0px;
    text-align: center;
    font-size: 14px;
  }

  .p_span > span {
    font-size: 18px;
    font-weight: 600;
    color: var(--globalcolor1);
  }

  .home_secondsec {
    margin: 15px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background: var(--globalcolor4);
    border-radius: 15px;
    padding: 0px 20px 20px;
  }

  .home_Secondsec_left {
    width: 100%;
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .home_Secondsec_left > h2 {
    font-size: 25px;
    font-weight: 900;
    text-align: center;
  }

  .home_Secondsec_left > img {
    margin-top: 0px;
    display: none;
  }

  .home_Secondsec_right {
    width: 100%;
    padding: 20px;

    background-color: var(--globalcolor4);
    margin-bottom: 15px;
  }

  .home_Secondsec_right > img {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
  }

  .home_forthsec {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: top;
    gap: 0px;
    width: 100%;
    box-sizing: border-box;
  }

  .home_forthsec_box1 {
    width: 100%;
  }

  .forthsec_boximg {
    width: 100%;
  }

  .home_forthsec_box2 {
    width: 100%;
    margin-top: -300px;
  }

  .forthsec_boximg1 {
    position: relative;
    bottom: 400px;
    left: 175px;
  }

  .forthsec_boximg2 {
    position: relative;
    bottom: 330px;
    left: -26px;
  }

  .home_thirdsec {
    padding: 30px 15px;
  }

  .home_thirdsec > h2 {
    font-size: 32px;
    text-align: center;
  }

  .home_third_boxcontainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    align-items: center;
  }

  .home_fifthsec {
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .home_fifthleft {
    width: 100%;
    padding-right: 0px;
  }

  .home_fifthleft > h2 {
    font-size: 48px;
    line-height: 50px;
  }

  .home_fifthleft h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0px 5px;
  }

  .home_fifthleft p {
    font-size: 20px;
    font-weight: 400;
  }

  .home_inputcontainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .home_inputcontainer input {
    width: 100%;
    margin-top: 20px;
    padding: 5px 0px 10px;
    border-bottom: 1px solid var(--globalcolor5);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 18px;
    color: var(--globalcolor5);
  }

  .home_inputcontainer > button {
    width: 30%;
    padding: 5px 0px 0px;
    border-bottom: 0px solid var(--globalcolor5);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 16px;

    text-align: right;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .home_fifthright {
    width: 100%;
    margin-top: 30px;
  }

  .home_fifthleft h4 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
  }

  .home_fifthimgcontainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
  }

  .home_fifthimg1 {
    position: relative;
    left: 30px;
  }

  .home_fifthimg2 {
    position: relative;
    right: 0px;
    bottom: 0px;

    border-radius: 12px;
    display: none;
  }

  .home_fifthimg3 {
    position: relative;
    left: 0px;
    bottom: 0px;

    border-radius: 12px;
    display: none;
  }

  .home_fifthimgcontainer > img {
    width: 45%;
  }

  .home_testimonial {
    padding: 80px 20px 150px;
    background-color: var(--globalcolor3);
    margin-top: 200px;
  }

  .home_testimonial > h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 80px;
    color: var(--globalcolor1);
  }
  .home_lastsecond {
    background-color: var(--globalcolor1);
    padding: 50px 20px;
    margin: 0px 50px;
    margin-top: -100px;
    border-radius: 20px;
  }
  .home_lastsecond > h2 {
    color: var(--globalcolor4);
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 38px;
  }
  .home_lastsection {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 50px 20px;
  }
  .home_lastsec_box {
    width: 100%;
    background-color: var(--globalcolor3);
    padding: 80px 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .shape3 {
    display: none;
  }
}
