
.featurepage_Services{
        display: flex;
        flex-direction: row;
        gap: 25px;
        padding: 30px;
        justify-content: center;
        flex-wrap: wrap;
}
.featurepage_Secondsec{
    padding: 30px;

}
.featurepage_thirdsec{
    background-image: url('https://whatsapp.hitoritech.com/assets/frontend/img/choose/choose-bg.png');
    background-repeat: no-repeat;
    background-position: 0%;
    background-size: cover;
    margin-top: 50px;
    padding: 100px 0px 150px;
    color: var(--globalcolor4);
    text-align: center;
}
.featurepage_thirdsec>h1{
    font-size: 52px;
}
.featurepage_thirdsec>p{
    font-size: 20px;
    margin-top: 15px;
    font-weight: 500;
}
.featurepage_imgcontainer{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
    
}





@media (max-width:1100px){
    .featurepage_Services{
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 30px;
        padding-left: 30px;
        flex-wrap: wrap;
}
}
@media (max-width:650px){
    .featurepage_Services{
    display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding: 20px;
        align-items: center;
    }
    .featurepage_thirdsec{
        padding: 100px 30px 250px;
    }
    .featurepage_thirdsec>h1{
        font-size: 35px;
    }
    .featurepage_imgcontainer>img{
        width: 50%;
    }
    }
